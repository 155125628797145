<template>

  <div>
    <marquee>
      <h4
        v-if="infoStudentAboutToExpired != null"
        style="color: red"
      >
        Cảnh báo, sắp hết hạn thời gian bảo lưu {{ getFormattedDate(infoStudentAboutToExpired.expireDate) }}!
      </h4>
    </marquee>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
        lg="4"
      >
        <b-card no-body>
          <b-card-header class="bg-success text-white">
            <div class="d-flex align-items-center">
              <feather-icon
                size="42"
                class="mr-1"
                icon="TargetIcon"
              />
              <b-card-title class="ml-25 text-uppercase text-white">
                Kết quả học tập
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body class="p-1 font-medium-2">
            <b-card-text>
              Điểm TB học kỳ gần nhất: <strong class="text-success">
              {{ (studentCgpaInfo.gpaFourth !== null && studentCgpaInfo.gpaFourth ) ? studentCgpaInfo.gpaFourth : 'Chưa có' }}
              {{ (studentCgpaInfo.gpaTenth !== null && studentCgpaInfo.gpaTenth ) ? ' (' + studentCgpaInfo.gpaTenth + ')' : '' }}
            </strong>
            </b-card-text>
            <b-card-text>
              Điểm TB tích lũy: <strong class="text-success">
              {{ (studentCgpaInfo.cgpaFourth !== null && studentCgpaInfo.cgpaFourth) ? studentCgpaInfo.cgpaFourth : 'Chưa có' }}
              {{ (studentCgpaInfo.cgpaTenth !== null && studentCgpaInfo.cgpaTenth) ? ' (' + studentCgpaInfo.cgpaTenth + ')' : '' }}
            </strong>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="4"
      >
        <b-card no-body>
          <b-card-header class="bg-warning text-white">
            <div class="d-flex align-items-center">
              <feather-icon
                size="42"
                md-size="32"
                class="mr-1"
                icon="AwardIcon"
              />
              <b-card-title class="ml-25 text-white">
                TRÌNH ĐỘ SINH VIÊN
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body class="p-1 font-medium-2">
            <b-card-text>
              <strong class="text-success">{{
                  studentCgpaInfo.schoolYearLevelName || '---'
                }}</strong> <span>({{ studentCgpaInfo.totalCredit || '---' }} Tín chỉ tích lũy)</span>
            </b-card-text>
            <b-card-text>
              <span> Cảnh báo: </span><strong class="text-danger"> {{ alertString }}</strong>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="4"
      >
        <b-card no-body>
          <b-card-header :class="`text-white bg-${(studentInfoReception.status<2 && studentInfoReception.total>0)?'danger':'info'}`">
            <div class="d-flex align-items-center">
              <feather-icon
                size="42"
                class="mr-1"
                icon="DollarSignIcon"
              />
              <b-card-title class="ml-25 text-white">
                HỌC PHÍ
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body class="p-1 font-medium-2">
            <b-card-text>
              Tổng đã nộp: <strong class="badge badge-primary">{{ showCurrencyVND((studentInfoReception.status===2)?studentInfoReception.amount:0) }}</strong>
            </b-card-text>
            <b-card-text>
              Tổng chưa nộp: <strong class="badge badge-danger">{{ showCurrencyVND((studentInfoReception.status===1)?studentInfoReception.total:0) }}</strong>
              <b-button
                v-if="studentInfoReception.status < 2 && studentInfoReception.total>0"
                variant="success"
                size="sm"
                class="float-md-right ml-1 ml-md-0"
                title="Thanh toán"
                @click="openPaymentTransaction()"
              >
                <feather-icon
                  icon="CreditCardIcon"
                  class="d-block d-sm-none d-md-block d-xl-none"
                />
                <span class="d-none d-sm-block d-md-none d-xl-block">Thanh toán</span>
              </b-button>
            </b-card-text>
            <b-card-text>
              <span style="float: left">Miễn giảm: <strong class="badge badge-success">
                {{ studentInfoReception.total_exemption ? showCurrencyVND(studentInfoReception.total_exemption) : 0 }}</strong></span>
              <span style="float: right">
                <router-link class="my-link" target="_blank" :to="{ name: 'studentReceipt' }">
                  Xem chi tiết
                </router-link>
              </span>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="bg-primary text-white">
            <div class="d-flex align-items-center">
              <feather-icon
                size="24"
                class="mr-1"
                icon="CalendarIcon"
              />
              <b-card-title class="ml-25 text-white">
                THỜI KHÓA BIỂU
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body class="p-1 ">
            <viewSchedule />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-transaction"
      ref="modal-transaction"
      title="Thanh toán học phí"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <Payment
        :info-reception="studentInfoReception"
        @success="onTransactionSuccess"
      />
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import {
  BButton, BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import viewSchedule from '@/views/study/schedule/StudentSchedule.vue'
import Payment from '@/views/payment/Payment.vue'

export default {
  name: 'Dashboard',
  components: {
    Payment,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BButton,
    viewSchedule,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        code: '',
        name: '',
      },
      studentInfoReception: {},
      studentRating: {
        schoolYearLevelName: '',
        totalCredit: 0,
      },
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
      classId: 0,
      courseId: 0,
      courseSemesterId: 0,
      user: getUser(),
      infoReceiptRequest: {},
      infoRePortRequest: {},
    }
  },
  computed: {
    ...mapGetters({
      infoStudentAboutToExpired: 'student/infoStudentExpired',
      infoReception: 'student/infoReception',
    }),
    studentCgpaInfo() {
      const { programmes, portalProgrammeId } = this.user
      return programmes.find(element => element.id === portalProgrammeId)
    },
    alertString() {
      let alertString = 'Không'
      const { programmes, portalProgrammeId } = this.user
      const selectedProgramme = programmes.find(element => element.id === portalProgrammeId)
      // eslint-disable-next-line eqeqeq
      alertString = (selectedProgramme.academicWarned == 1) ? 'Bạn đã bị cảnh báo học tập kỳ này!' : alertString
      // eslint-disable-next-line eqeqeq
      alertString = (selectedProgramme.isSuspended == 1) ? 'Bạn đã bị buộc thôi học!' : alertString
      return alertString
    },
  },
  async created() {
    await this.getInitData()
  },

  methods: {
    ...mapActions({
      changePassword: 'student/changePassword',
      getInfoStudentAboutToExpired: 'student/getInfoStudentAboutToExpired',
      getInfoReception: 'student/getInfoReception',
      getPaymentByTransactionId: 'payment/getPaymentByTransactionId',
    }),
    getStudentInfoReception() {
      let studentInfoReception = { ...this.infoReception[0] }
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < this.infoReception.length; i++) {
        studentInfoReception.total_exemption += this.infoReception[i].total_exemption
        studentInfoReception.amount += this.infoReception[i].amount
        studentInfoReception.total += this.infoReception[i].total
        studentInfoReception.status = (this.infoReception[i].status > studentInfoReception.status) ? this.infoReception[i].status : studentInfoReception.status
      }
      return studentInfoReception
    },
    async getInitData() {
      const { programmes, portalProgrammeId } = this.user
      const found = programmes.find(element => element.id === portalProgrammeId)

      this.infoReceiptRequest = {
        day_start: '',
        day_end: '',
        class_id: (found) ? found.classId : 0,
        course_id: (found) ? found.courseId : 0,
        student_id: this.user.studentId,
        semester_id: (found) ? found.courseSemesterId : 0,
      }

      await Promise.all([
        this.getInfoStudentAboutToExpired({ studentId: this.user.studentId }),
        this.getInfoReception(this.infoReceiptRequest),
      ])
      this.studentInfoReception = this.getStudentInfoReception()
    },

    async handleChangePassword() {
      if (this.newPassword !== this.reNewPassword) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nhập lại mật khẩu mới chưa khớp',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      const body = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }
      try {
        const res = await this.changePassword({ id: this.user.accountId, body })
        if (res.isSuccessful) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thay đổi mật khẩu thành công',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('modal-change-password')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    getFormattedDate(date) {
      return (date) ? moment(date).format('DD/MM/yyyy') : ''
    },
    showCurrencyVND(value) {
      // Create our number formatter.
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      })

      return formatter.format(value)
    },

    openPaymentTransaction() {
      this.$bvModal.show('modal-transaction')
    },

    async onTransactionSuccess() {
      await this.getInfoReception(this.infoReceiptRequest)
      this.$bvModal.hide('modal-transaction')
    },
  },

}
</script>

<style type="text/css" >

</style>
