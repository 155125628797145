import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async getDropdownListProcedure() {
    try {
      const response = await axios.get('oneGate/procedure/getAllProcedures')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return mapDropdown(data.data)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async fetchTypeProcedureData() {
    try {
      const response = await axios.get('oneGate/typeProcedure/getAllTypeProcedures')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async searchDossier(params, bodySearch) {
    try {
      const response = await axios.post('oneGate/dossiers/searchDossier', bodySearch, params)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async getDetailDossier(params) {
    try {
      const response = await axios.get(`oneGate/dossiers/getDetailDossier/${params}`)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async downloadTemplate(params) {
    try {
      const response = await axios.get('oneGate/template-files/download', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async uploadDossierFile({ formData }) {
    try {
      const response = await axios.post(
        'oneGate/dossiers/uploadDossierFile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
      return []
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  // eslint-disable-next-line consistent-return
  async cancelDossier(params) {
    try {
      const response = await axios.delete(`oneGate/dossiers/cancelDossier/${params}`)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async submitDossiers(dossierData) {
    try {
      const response = await axios.post('oneGate/dossiers/submitDossier', dossierData)

      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data
      }
      return []
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  // eslint-disable-next-line consistent-return
  async deleteListMediaId(listObject) {
    try {
      const mediaIds = listObject.map(item => item.mediaId)
      const response = await axios.post('uni/media/deleteListMediaId', mediaIds)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
  },
}
