<template>
  <validation-observer
    ref="detailProcedureFormRef"
    #default="{ invalid }"
  >
    <b-modal
      id="viewProcedureModal"
      body-class="position-static"
      centered
      title="Chi tiết thủ tục"
      modal-class="custom-modal-size"
      :no-close-on-backdrop="true"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <b-form-group
                label-for="code"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex align-items-center">
                  <strong class="label-width">Mã thủ tục:</strong>
                  <div class="ml-2 value-align">
                    <label>{{ targetItem.code }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <b-form-group
                label-for="name"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex align-items-center">
                  <strong class="label-width">Tên thủ tục:</strong>
                  <div class="ml-2">
                    <label>{{ targetItem.name }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <b-form-group
                label-for="procedureType"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex align-items-center">
                  <strong class="label-width">Loại thủ tục:</strong>
                  <div class="ml-2">
                    <label>{{ targetItem.typeName }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <b-form-group label-for="processId">
                <validation-provider
                  #default="{ errors }"
                  vid="processId"
                  rules="required"
                >
                  <div class="d-flex align-items-center">
                    <div class="mr-4">
                      <strong class="label-width">Quy trình:</strong>
                    </div>
                    <div class="flex-grow-1">
                      <v-select
                        v-model="targetItem.processId"
                        label="label"
                        :options="listProcessByProcedureId"
                        :reduce="option => option.value"
                        :clearable="false"
                        @input="onChangeProcess"
                      />
                      <b-form-invalid-feedback :state="getElementState(errors)">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex align-items-center">
                  <strong class="label-width">Phí/Lệ phí:</strong>
                  <div class="ml-2">
                    <label>{{ formatCurrency(targetItem.fees) }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="procedureProcess">
                <div>
                  <strong class="label-width">Phòng ban thực hiện:</strong>
                  <div class="mt-1">
                    <b-table
                      :items="listInfoDepartment"
                      :fields="departmentsFields"
                    />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="documentDetails">
                <div>
                  <strong class="label-width">Hồ sơ đính kèm:</strong>
                  <div class="mt-1">
                    <b-table
                      :items="listStepDocument"
                      :fields="documentFields"
                      responsive
                      striped
                      hover
                    >
                      <template #cell(templateName)="row">
                        <div
                          v-b-tooltip.hover.top="{ title: row.item.templateName }"
                          class="template-name"
                        >
                          <span
                            v-show="row.item.isRequired === 1"
                            class="text-danger"
                          >
                            (*)
                          </span>
                          {{ row.item.templateName }}
                        </div>
                      </template>
                      <template #cell(download)="row">
                        <div class="media-list">
                          <div
                            v-for="(media, index) in row.item.mediaIds"
                            :key="index"
                            class="media-item d-flex align-items-center justify-content-between"
                          >
                            <!-- Hiển thị tên file với xử lý dài -->
                            <span
                              class="media-name text-truncate"
                              :title="media.mediaName"
                            >
                              {{ media.mediaName }}
                            </span>

                            <b-button
                              v-if="media.mediaCode"
                              v-b-tooltip.hover.top="'Tải về file mẫu'"
                              variant="success"
                              class="btn-icon"
                              style="margin-left: 6px;"
                              size="sm"
                              @click="onDownload(media.mediaCode)"
                            >
                              <feather-icon icon="DownloadIcon" />
                            </b-button>
                          </div>
                        </div>
                      </template>
                      <template #cell(quantity)="row">
                        <div class="quantity text-center d-inline-flex">
                          {{ row.item.mediaIds.length }}
                        </div>
                      </template>
                    </b-table>
                    <div class="col-12 text-right">
                      <span class="text-danger">(*)</span>
                      <span class="note"> Tệp cần tải lên</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            variant="success"
            class="mr-2"
            :disabled="isEnable"
            @click="openSubmitForm"
          >
            <span class="text-right">
              <feather-icon icon="CheckSquareIcon" /> Nộp hồ sơ
            </span>
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="$bvModal.hide('viewProcedureModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Đóng
            </span>
          </b-button>
        </div>
      </template>
      <!--      </b-overlay>-->
    </b-modal>
  </validation-observer>

</template>

<script>
import {
  BForm,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BTable,
  BOverlay,
  BModal, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import api from '@/views/one-gate/og-submit-dossiers/submit-dossiers-api'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { OG_PROCEDURE_TYPE, TEMPLATE_FILE_TYPE } from '@/const/status'

export default {
  name: 'FormDetail',
  components: {
    BModal,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BTable,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: undefined,
    },
    dataSendForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        id: '',
        name: '',
        code: '',
        type: '',
        processId: '',
        processName: '',
        fees: 0,
      },
      departmentsFields: [
        { key: 'orderNo', label: 'Thứ tự', thStyle: { width: '150px' } },
        { key: 'departmentName', label: 'Phòng ban xử lý', thStyle: { width: '250px' } },
        { key: 'employeeFullName', label: 'Người xử lý', thStyle: { width: '250px' } },
      ],
      documentFields: [
        { key: 'templateName', label: 'Tên giấy tờ', thStyle: { width: '350px' } },
        {
          key: 'download', label: 'Tải về file mẫu', thStyle: { width: '50%', textAlign: 'center' }, tdClass: 'text-center',
        },
        {
          key: 'quantity', label: 'Số lượng', thStyle: { width: '25%', textAlign: 'center' }, tdClass: 'text-center',
        },
      ],
      listProcessByProcedureId: [],
      listInfoDepartment: [],
      listStepDocument: [],
      isEnable: true,
      currentProcedure: undefined,
      dataSendSubmit: {
        listStepDocument: [],
        targetItem: {},
      },
    }
  },
  mounted() {
    const style = document.createElement('style')
    style.innerHTML = `
      .custom-modal-size .modal-dialog {
        max-width: 70% !important;
        margin: auto !important;
      }
    `
    document.head.appendChild(style)
    this.customStyleElement = style
  },
  methods: {
    async onShow() {
      // await this.clearData()
      this.listInfoDepartment = []
      this.listStepDocument = []
      this.isEnable = true
      this.targetItem = this.dataSendForm ? { ...this.dataSendForm } : {}
      this.targetItem.fees = 0
      await this.getProcessByProcedureId(this.targetItem.id)
    },
    onHide() {
      this.$refs
        .detailProcedureFormRef
        .reset()
    },
    async getProcessByProcedureId(procedureId) {
      this.isLoading = true
      await api.getDropdownListProcess({ procedureId }).then(res => {
        this.listProcessByProcedureId = res
        if (!this.targetItem.processId && this.listProcessByProcedureId.length > 0) {
          this.targetItem.processId = this.listProcessByProcedureId[0].value
          this.targetItem.processName = this.listProcessByProcedureId[0].label
          this.onChangeProcess(this.targetItem.processId)
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    async onChangeProcess(processId) {
      this.isLoading = true
      this.listInfoDepartment = []
      this.listStepDocument = []
      // Gọi API để lấy thông tin dựa trên processId
      try {
        await api.selectInfoProcedure({ processId }).then(response => {
          this.listInfoDepartment = response.result1 ? response.result1 : []
          this.listStepDocument = response.result2 ? response.result2 : []
          if (this.listInfoDepartment.length > 0) {
            const newFees = this.listInfoDepartment[0].fees || 0
            this.targetItem = { ...this.targetItem, fees: newFees }
            this.isEnable = false
          } else {
            this.targetItem = { ...this.targetItem, fees: 0 }
            this.isEnable = true
          }
          const filtered = this.listProcessByProcedureId.filter(data => data.value === processId)
          this.targetItem = {
            ...this.targetItem,
            processName: filtered.length > 0 ? filtered[0].label : undefined,
          }
          this.listInfoDepartment.sort((a, b) => a.orderNo - b.orderNo)
          this.listStepDocument.sort((a, b) => a.stepId - b.stepId)
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        const type = this.getIdTemplate(1)
        this.url = await api.downloadTemplate({ objectPath, type })
        if (this.url) {
          window.open(this.url, '_blank')
        }
        this.isLoading = false
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    openSubmitForm() {
      this.dataSendSubmit.listStepDocument = [...this.listStepDocument]
      this.dataSendSubmit.targetItem = { ...this.targetItem }
      this.dataSendSubmit.listStepDocument = this.dataSendSubmit.listStepDocument.map(item => {
        const { file, ...rest } = item
        return rest
      })
      this.$emit('open-submit-form', this.dataSendSubmit)
      this.onHide()
    },
    formatCurrency(value) {
      if (value != null) {
        return `${value.toLocaleString('vi-VN')} VND`
      }
      return '0 VND'
    },
    getIdTemplate(value) {
      return TEMPLATE_FILE_TYPE.find(item => item.value === value).value
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

.v-select span {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.v-select {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.template-name,
.d-inline-flex span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  max-height: 4.5em;
}

.media-code {
  width: 15%;
}

.quantity {
  width: 15%;
}

label{
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.label-width {
  width: 100px;
  text-align: left;
  font-size: 14px !important;
  color: #5e5873 !important;
  min-width: 100px;
}

.value-align {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.note {
  font-style: italic;
  font-size: 0.85rem;
  color: #6c757d; /* Màu ghi chú */
}

.media-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.media-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media-name {
  font-size: 14px;
  color: #333;
  margin-right: 8px;
}

.media-name {
  display: inline-block;
  max-width: 350px; /* Độ rộng tối đa hiển thị */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #333;
  margin-right: 8px;
  cursor: pointer;
}

</style>
