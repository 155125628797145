<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card class="shadow-sm">
        <div class="ml-2 mr-2">
          <div class="search-section mb-2">
            <b-row class="d-flex align-items-center">
              <!-- Label -->
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start text-left"
              >
                <h2 class="mb-0">Tìm kiếm thủ tục</h2>
              </b-col>

              <!-- Input Field -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center"
              >
                <b-form-group label-for="dossierName" class="w-100 mb-0">
                  <b-form-input
                    id="dossierName"
                    v-model="serverParams.columnFilters.name"
                    placeholder="Nhập tên thủ tục cần tìm kiếm"
                    name="dossierName"
                    @keyup.enter="loadItems()"
                    autofocus
                  />
                </b-form-group>
              </b-col>

              <!-- Search Button -->
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mt-2 mt-md-0"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="loadItems()"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="align-text-top"
                  />
                  Tìm kiếm
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col cols="12">
              <vue-good-table
                mode="remote"
                :columns="table.fields"
                :rows="table.rows"
                :pagination-options="{ enabled: true }"
                :total-rows="totalRecords"
                class="custom-table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'action'">
                    <b-button
                      v-b-tooltip.hover
                      v-b-modal.viewProcedureModal
                      title="Xem chi tiết"
                      placement="bottom"
                      variant="info"
                      class="btn-icon"
                      @click="viewDetail(props.row)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field === 'download'">
                    <b-link :href="props.row.url">
                      <b-button
                        variant="success"
                        class="btn-icon"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                    </b-link>
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRecords }} bản ghi
                                        </span>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRecords"
                        :per-page="serverParams.perPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <FormDetail
        v-show="!closeAllPopup"
        ref="detailProcedureFormRef"
        :data-send-form="currentProcedure"
        @open-submit-form="handleOpenPopupSubmit"
      />
      <FormSubmit
        v-show="isPopupSubmitVisible&&!closeAllPopup"
        ref="submitProcedureFormRef"
        :data-send-form="dataSendForm"
        @submitDossiersSuccess="submitDossiersSuccess"
      />

    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BOverlay, BPagination, BRow, BLink, VBTooltip, BFormInput, BFormGroup
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import FormDetail from '@/views/one-gate/og-submit-dossiers/FormDetail.vue'
import Ripple from 'vue-ripple-directive'
import FormSubmit from '@/views/one-gate/og-submit-dossiers/FormSubmit.vue'
import api from '@/views/one-gate/og-submit-dossiers/submit-dossiers-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BFormInput,
    FormSubmit,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    VueGoodTable,
    FormDetail,
    BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        code: null,
        status: 1,
        sort: null,
      },
      table: {
        fields: [
          {
            label: 'STT',
            field: 'rowNum',
            sortable: false,
            width: '5%',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'Mã thủ tục',
            field: 'code',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
            },
            width: '15%',
            sortable: false,
          },
          {
            label: 'Tên thủ tục',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
            },
            field: 'name',
            sortable: false,
          },
          {
            label: 'Mô tả',
            field: 'description',
            sortable: false,
          },
          {
            label: 'Thao tác',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10%',
            sortable: false,
          },
        ],
        rows: [],
      },
      serverParams: {
        columnFilters: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      itemsDisplay: [],
      dataSendForm: {
        listStepDocument: [],
        targetItem: {},
      },
      currentProcedure: undefined,
      isPopupSubmitVisible: false,
      closeAllPopup: false,
      searchData: '',
    }
  },
  async created() {
    await this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.loadItems()
    },
    async onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      await this.loadItems()
    },
    async onSortChange(params) {
      this.updateParams({
        sort: [{ type: params[0].type, field: params[0].field }],
      })
      await this.loadItems()
    },
    async onColumnFilter(params) {
      this.updateParams(params)
      await this.loadItems()
    },
    viewDetail(item) {
      this.closeAllPopup = false
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = item
      this.currentProcedure = rest
    },
    async loadItems() {
      this.isLoading = true
      const param = {
        currentPage: this.serverParams.page,
        itemsPerPage: this.serverParams.perPage,
        code: this.serverParams.columnFilters.code ? this.serverParams.columnFilters.code.trim() : '',
        name: this.serverParams.columnFilters.name ? this.serverParams.columnFilters.name.trim() : '',
      }
      await api.fetchItems(param).then(res => {
        const { records, total } = res.data
        this.itemsDisplay = records
        this.totalRecords = total
        this.table.rows = this.itemsDisplay
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    submitDossiersSuccess(data) {
      if (data) {
        this.closeAllPopup = true
        this.$bvModal.hide('viewProcedureModal')
        this.$bvModal.hide('submitProcedureModal')
        this.$router.push('/personal-dossiers')
      }
    },
    handleOpenPopupSubmit(data) {
      this.closeAllPopup = false
      this.isPopupSubmitVisible = true
      // this.$bvModal.hide('viewProcedureModal')
      this.dataSendForm.listStepDocument = data.listStepDocument
      this.dataSendForm.targetItem = data.targetItem
      this.$bvModal.show('submitProcedureModal')
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.search-button {
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.custom-table {
  border: 1px solid #dee2e6;
}

.custom-table .vgt-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.custom-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.custom-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table tr:hover {
  background-color: #e9ecef;
}

.search-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

</style>
