import { ResourceCode } from '@/const/code';

export default [
  {
    path: '/submit-dossiers',
    name: 'submitDossiers',
    component: () => import('@/views/one-gate/og-submit-dossiers/index.vue'),
    meta: {
      // pageTitle: 'Nộp hồ sơ',
      resource: ResourceCode.OG_PORTAL_SUBMIT_DOSSIERS,
    },
  },
  {
    path: '/personal-dossiers',
    name: 'personalDossiers',
    component: () => import('@/views/one-gate/og-personal-dossiers/index.vue'),
    meta: {
      pageTitle: 'Quản lý hồ sơ cá nhân',
      resource: ResourceCode.OG_PORTAL_PERSONAL_DOSSIERS,
    },
  },
]
