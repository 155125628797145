<template>
  <validation-observer
    ref="detailsDossierFormRef"
    #default="{ invalid }"
  >
    <b-modal
      id="detailsDossierModal"
      body-class="position-static"
      modal-class="custom-modal-size"
      centered
      title="Chi tiết hồ sơ "
      no-close-on-backdrop
      no-close-on-esc
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-row>
            <!-- Mã thủ tục -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="code"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width m-auto">Mã hồ sơ:</strong>
                  <div class="ml-2 value-align">
                    <label class="m-auto">{{ mappingDataDetail.code }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Tên thủ tục -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="name"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Tên thủ tục:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ mappingDataDetail.procedureName }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Phí -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Phí/Lệ phí:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ formatCurrency(mappingDataDetail.fees) }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Quy trình -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Quy trình:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ mappingDataDetail.processName }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Trạng thái:</strong>
                  <div class="ml-2">
                    {{ getStatus }}
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <!--Mở lại sau khi có phần thanh toán-->
            <div v-show="false">
              <!-- Thông tin ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <div class="d-flex align-items-center">
                  <strong class="label-width">Thông tin ngân hàng:</strong>
                  <div class="ml-2">
                    <validation-provider name="Thông tin ngân hàng">
                      <b-form-input
                        id="bankName"
                        v-model="mappingDataDetail.bankName"
                        name="bankName"
                        placeholder="Thông tin ngân hàng"
                        aria-describedby="input-1-live-feedback"
                      />
                    </validation-provider>
                  </div>
                </div>
              </b-col>

              <!-- Mã ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Mã ngân hàng:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="bankCode"
                        v-model="mappingDataDetail.bankCode"
                        placeholder="Nhập mã ngân hàng"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <!-- Số tài khoản ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Số tài khoản ngân hàng:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="accountNumber"
                        v-model="mappingDataDetail.accountNumber"
                        placeholder="Nhập số tài khoản"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <!-- Họ tên chủ tài khoản -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Họ tên chủ tài khoản:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="accountHolderName"
                        v-model="mappingDataDetail.accountHolderName"
                        placeholder="Nhập họ tên chủ tài khoản"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </div>
            <!-- Thành phần hồ sơ -->
            <b-col cols="12">
              <b-form-group label-for="documentDetails">
                <div>
                  <strong class="label-width">Hồ sơ đính kèm:</strong>
                  <div class="mt-1">
                    <b-table
                      :key="tableKey"
                      :items="mappingDataDetail.listStepDocument"
                      :fields="documentFields"
                      responsive
                      striped
                      hover
                    >
                      <template #cell(templateName)="row">
                        <div
                          :title="row.item.mediaFiles[0].templateName"
                          class="template-name"
                        >
                          <span
                            v-show="row.item.mediaFiles[0].isRequired === 1"
                            class="text-danger"
                          >(*)</span>
                          {{ row.item.mediaFiles[0].templateName }}
                        </div>
                      </template>
                      <template #cell(quantity)="row">
                        <div class="quantity text-center d-inline-flex">
                          {{ row.item.mediaFiles.filter(file => file.mediaId).length }}
                        </div>
                      </template>
                      <template #cell(upload)="row">
                        <div class="d-inline-flex flex-column">
                          <!-- Hiển thị danh sách file đã upload -->
                          <template v-if="row.item.mediaFiles && row.item.mediaFiles.length">
                            <div
                              v-for="(file, index) in row.item.mediaFiles"
                              :key="index"
                              class="d-flex align-items-center mb-1 justify-content-between"
                            >
                              <span
                                v-if="file.mediaCode"
                                v-b-tooltip.hover.top="'Click để tải file đã upload'"
                                class="text-primary cursor-pointer"
                                @click="onDownload(file.mediaCode)"
                              >
                                {{ file.mediaName }}
                              </span>
                              <span
                                v-else
                                class="text-muted cursor-not-allowed"
                              >
                                {{ file.mediaName }}
                              </span>
                              <b-button
                                v-if="enableAttachFile && row.item.status !== 1"
                                v-b-tooltip.hover.top="'Xóa file'"
                                variant="danger"
                                class="btn-icon ml-2"
                                size="sm"
                                @click="onRemoveFile(row.item, index)"
                              >
                                <feather-icon icon="XIcon" />
                              </b-button>
                            </div>
                          </template>
                          <!-- Nút để upload file -->
                          <b-button
                            v-if="enableAttachFile && row.item.status !== 1"
                            v-b-tooltip.hover.top="'Đính kèm thêm file thông tin'"
                            variant="info"
                            class="btn-icon mt-2"
                            size="sm"
                            @click="onUpload(row.item)"
                          >
                            <feather-icon icon="PaperclipIcon" />
                          </b-button>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>

      <!-- Modal Footer -->
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-if="enableAdditionDossier"
            variant="primary"
            class="mr-2"
            @click="onSubmit(DossierStatus.ADDITION_DOSSIER)"
          >
            <span class="text-right">
              <feather-icon icon="SaveIcon" /> Nộp bổ sung hồ sơ
            </span>
          </b-button>
          <b-button
            v-if="enableSubmitDossier"
            variant="primary"
            class="mr-2"
            @click="onSubmit(DossierStatus.WAITING_FOR_RECEPTION)"
          >
            <span class="text-right">
              <feather-icon icon="SaveIcon" /> Nộp hồ sơ
            </span>
          </b-button>
          <b-button
            v-if="enableCancel"
            variant="warning"
            class="mr-2"
            @click="onCancelDossier"
          >
            <span class="text-right">
              <feather-icon icon="PowerIcon" /> Hủy hồ sơ
            </span>
          </b-button>

          <b-button
            variant="outline-secondary"
            @click="$bvModal.hide('detailsDossierModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Đóng
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BButton, BRow, BCol, BFormInput, BFormGroup, BTable, BOverlay, BModal, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import api from '@/views/one-gate/og-personal-dossiers/personal-dossiers-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  DOSSIER_STATUS,
} from '@/const/status'
import Ripple from 'vue-ripple-directive'
import {
  DossierStatus, FileUploadStatus, TemplateFileStatus,
} from '@/const/dossier-status'

export default {
  name: 'FormDetailsDossier',
  components: {
    BModal,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    dataSendForm: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        id: '', name: '', code: '', type: '', processId: '', processName: '', dossierId: '', fees: 0, status: 0,
      },
      bankInfo: {
        bankName: '', bankCode: '', accountNumber: '', accountHolderName: '',
      },
      documentFields: [
        { key: 'templateName', label: 'Tên giấy tờ', thStyle: { width: '350px' } },
        {
          key: 'quantity', label: 'Số lượng', thStyle: { width: '15%', textAlign: 'center' }, tdClass: 'text-center',
        },
        {
          key: 'upload', label: 'Đính kèm file thông tin', thStyle: { width: '50%', textAlign: 'center' }, tdClass: 'text-center',
        },
      ],
      listProcessByProcedureId: [],
      listInfoDepartment: [],
      mappingDataDetail: {
        id: null,
        procedureId: null,
        procedureName: null,
        procedureType: null,
        processId: null,
        processName: null,
        fees: null,
        code: null,
        status: null,
        bankName: null,
        bankCode: null,
        bankAccountNo: null,
        bankAccountHolderName: null,
        listStepDocument: [],
      },
      isEnable: true,
      tableKey: Date.now(),
      fileNumber: 0,
      dataMapping: {
        dossierId: null,
        procedureId: null,
        processId: null,
        bankName: '',
        bankCode: '',
        accountNumber: '',
        accountHolderName: '',
        status: null,
        listMedia: [],
      },
      sessionMedia: [],
      checkError: null,
      stepRequireFile: 0,
    }
  },
  computed: {
    DossierStatus() {
      return DossierStatus
    },
    enableCancel() {
      const validStatuses = [
        DossierStatus.WAITING_FOR_RECEPTION,
        DossierStatus.REJECT,
        DossierStatus.REQUIRED_ADDITION_DOCUMENTS,
        DossierStatus.DRAFT,
        DossierStatus.ADDITION_DOSSIER,
      ]
      return validStatuses.includes(this.getDossierStatus(Number(this.mappingDataDetail.status)))
    },
    enableAttachFile() {
      const validStatuses = [
        DossierStatus.DRAFT,
        DossierStatus.REQUIRED_ADDITION_DOCUMENTS]
      return validStatuses.includes(this.getDossierStatus(Number(this.mappingDataDetail.status)))
    },
    enableAdditionDossier() {
      const validStatuses = [DossierStatus.REQUIRED_ADDITION_DOCUMENTS]
      return validStatuses.includes(this.getDossierStatus(Number(this.mappingDataDetail.status)))
    },
    enableSubmitDossier() {
      const validStatuses = [DossierStatus.DRAFT]
      return validStatuses.includes(this.getDossierStatus(Number(this.mappingDataDetail.status)))
    },
    getStatus() {
      // eslint-disable-next-line radix
      return this.getDossierLabelStatus(parseInt(this.mappingDataDetail.status))
    },
  },
  mounted() {
    const style = document.createElement('style')
    style.innerHTML = `
      .custom-modal-size .modal-dialog {
        max-width: 70% !important;
        margin: auto !important;
      }
    `
    document.head.appendChild(style)
    this.customStyleElement = style
  },
  beforeDestroy() {
    // Cleanup: Remove the dynamically added style element
    if (this.customStyleElement) {
      document.head.removeChild(this.customStyleElement)
    }
  },
  methods: {
    async onShow() {
      this.resetData()
      await this.fetchDetailDossier(this.dataSendForm.id)
    },
    onHide() {
      this.clearStepDocuments()
      this.tableKey = Date.now()
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        const type = TemplateFileStatus.TEMPLATE_ATTACHMENT_DOSSIER
        this.url = await api.downloadTemplate({ objectPath, type })
        if (this.url) window.open(this.url, '_blank')
      } catch (e) {
        this.showToast(e.message, 'XCircleIcon', 'danger')
      } finally { this.isLoading = false }
    },
    onUpload(row) {
      this.isLoading = true
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = '.pdf,.docx'
      fileInput.click()
      fileInput.addEventListener('change', this.handleFileChange.bind(this, row))
      this.isLoading = false
    },
    handleFileChange(row, event) {
      const files = Array.from(event.target.files) // Lấy danh sách file
      const maxFileSize = 50 * 1024 * 1024 // 50MB
      files.forEach(file => {
        if (file.size > maxFileSize) {
          this.showToast(`File "${file.name}" vượt quá dung lượng tối đa 50MB`, 'ErrorIcon', 'error')
          return // Bỏ qua file không hợp lệ
        }

        // Nếu file hợp lệ, thêm vào danh sách file
        const fileData = {
          file,
          id: Date.now(),
          mediaName: file.name,
          size: file.size,
          stepId: row.stepId,
          templateId: row.mediaFiles[0].templateId,
          status: FileUploadStatus.NEW_FILE,
        }

        if (!row.mediaFiles) {
          // Nếu chưa có danh sách file, khởi tạo mảng
          this.$set(row, 'mediaFiles', [])
        }
        row.mediaFiles.push(fileData) // Thêm file vào danh sách
        this.tableKey += 1

        if (row.isRequired === 1) {
          this.fileNumber += 1
        }
        this.showToast(`File "${file.name}" đã được tải lên thành công`, 'CheckIcon', 'success')
      })
    },
    async onSubmit(type) {
      this.isLoading = true
      try {
        await this.uploadDossierFile(type)
      } catch (error) {
        this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    async finalSubmitDossier(type) {
      this.isLoading = true
      this.dataMapping.procedureId = this.mappingDataDetail.procedureId
      this.dataMapping.processId = this.mappingDataDetail.processId
      this.dataMapping.dossierId = this.mappingDataDetail.id
      this.dataMapping.bankName = this.mappingDataDetail.bankName
      this.dataMapping.bankCode = this.mappingDataDetail.bankCode
      this.dataMapping.accountHolderName = this.mappingDataDetail.accountHolderName
      this.dataMapping.accountNumber = this.mappingDataDetail.accountNumber
      this.dataMapping.status = type
      await api.submitDossiers(this.dataMapping).then(response => {
        this.showToast(response.message, 'CheckIcon', 'success')
        this.$emit('succeed')
      }).catch(error => {
        if (this.sessionMedia.length > 0) {
          api.deleteListMediaId(this.sessionMedia)
        }
        this.checkError = Date.now()
        this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
      }).finally(() => {
        this.isLoading = false
        this.sessionMedia = []
      })
    },
    async uploadDossierFile(type) {
      const formData = new FormData()

      // Lọc các file hợp lệ từ danh sách `listStepDocument`
      const validFiles = this.mappingDataDetail.listStepDocument.filter(
        item => item.mediaFiles
          && item.mediaFiles.length
          && item.mediaFiles.some(file => file.status === FileUploadStatus.NEW_FILE),
      )

      // Duyệt qua các file hợp lệ và thêm vào formData
      validFiles.forEach(item => {
        item.mediaFiles.forEach((file, index) => {
          if (file.status === FileUploadStatus.NEW_FILE) {
            formData.append(
              `files[${item.stepId}_${file.templateId}_${index + 1}]`, // Thêm stt (index + 1)
              file.file,
            )
          }
        })
      })

      if ([...formData.entries()].length) {
        // Gửi yêu cầu upload tệp
        this.sessionMedia = await api.uploadDossierFile({ formData })
        this.dataMapping.listMedia.push(...this.sessionMedia)
        // Cập nhật trạng thái của các file sau khi upload thành công
        this.mappingDataDetail.listStepDocument.forEach(item => {
          item.mediaFiles.forEach(mediaFile => {
            if (mediaFile.file && mediaFile.file.status === DossierStatus.WAITING_FOR_RECEPTION) {
              // eslint-disable-next-line no-param-reassign
              mediaFile.file.status = DossierStatus.WAITING_FOR_PROCESSING
            }
          })
        })
      }
      await this.finalSubmitDossier(type)
    },
    async fetchDetailDossier(dossierId) {
      this.isLoading = true
      try {
        const res = await api.getDetailDossier(dossierId)
        this.mappingDataDetail = res.data
        this.mappingDataDetail.listStepDocument = res.data.dossierFiles

        // Initialize listMedia if it doesn't exist
        if (!this.mappingDataDetail.listMedia) {
          this.mappingDataDetail.listMedia = []
        }

        // Initialize listMedia if it doesn't exist
        if (!this.mappingDataDetail.listStepDocument.mediaFiles) {
          this.mappingDataDetail.listStepDocument.mediaFiles = []
        }

        // Map through listStepDocument and push to listMedia
        this.mappingDataDetail.listStepDocument.forEach(
          data => {
            data.mediaFiles.forEach(mediaFile => {
              this.dataMapping.listMedia.push({
                title: `${mediaFile.name}`,
                mediaId: `${mediaFile.dossierMediaId}`,
              })
            })
            this.stepRequireFile += data.mediaFiles.length
          },
        )
      } catch (error) {
        this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    async onCancelDossier() {
      this.$swal({
        title: 'Bạn có chắc chắn muốn hủy hồ sơ này không?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
        cancelButtonText: '<i class="las la-times fs-3"></i> Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          await api.cancelDossier(this.mappingDataDetail.id).then(response => {
            this.showToast(response.message, 'CheckIcon', 'success')
            this.mappingDataDetail = { ...this.mappingDataDetail, status: this.getDossierStatus(6) }
            this.$emit('succeed')
          }).catch(error => {
            this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
    onRemoveFile(row, index) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa file này không?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
        cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const removedFile = row.mediaFiles.splice(index, 1) // Xóa file khỏi danh sách
          const indexStep = this.mappingDataDetail.listStepDocument.findIndex(
            data => data.mediaFiles.some(doc => doc.id === removedFile[0].id),
          )
          this.showToast(`File "${removedFile[0].mediaName}" đã được xóa`, 'TrashIcon', 'info')
          if (indexStep !== -1) {
            // Lọc và cập nhật lại danh sách this.dataMapping.listMedia
            this.dataMapping.listMedia = this.dataMapping.listMedia.filter(
              data => data.id !== row.id,
            )
            const mediaFileIndex = this.mappingDataDetail.listStepDocument[indexStep].mediaFiles.findIndex(
              doc => doc.id === row.id,
            )

            // Nếu tìm thấy, xóa phần tử tại index đó
            if (mediaFileIndex !== -1) {
              this.mappingDataDetail.listStepDocument[indexStep].mediaFiles.splice(mediaFileIndex, 1)
            }
            this.tableKey += 1
            if (row.isRequired === 1 && removedFile.length) {
              this.fileNumber -= 1
            }
            this.isEnable = true
          }
        }
      })
    },
    // onRemoveFile(item) {
    //   this.$swal({
    //     title: 'Bạn có chắc chắn muốn xóa file này không?',
    //     text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
    //     cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(async result => {
    //     if (result.value) {
    //       const index = this.mappingDataDetail.listStepDocument.findIndex(doc => doc.stepId === item.stepId && doc.templateId === item.templateId)
    //       if (index !== -1) {
    //         // Xóa file trong danh sách listStepDocument
    //         this.$set(this.mappingDataDetail.listStepDocument[index], 'file', null)
    //
    //         // Lọc và cập nhật lại danh sách this.dataMapping.listMedia
    //         this.dataMapping.listMedia = this.dataMapping.listMedia.filter(
    //           data => data.title !== `${item.stepId}_${item.templateId}`,
    //         )
    //         this.tableKey += 1
    //         this.fileNumber -= 1
    //         this.isEnable = true
    //       }
    //     }
    //   })
    // },
    formatCurrency(value) { return value ? `${value.toLocaleString('vi-VN')} VND` : '0 VND' },
    getDossierStatus(value) {
      return DOSSIER_STATUS.find(item => item.value === value).value
    },
    getDossierLabelStatus(value) {
      return value !== null && value !== undefined
        ? DOSSIER_STATUS.find(status => status.value === value)?.label || ''
        : ''
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: { title, icon, variant },
      })
    },
    resetData() {
      // eslint-disable-next-line no-multi-assign
      this.listInfoDepartment = this.mappingDataDetail.listStepDocument = this.sessionMedia = []
      this.tableKey = Date.now()
      this.fileNumber = 0
      this.isEnable = true
      this.bankInfo = {}
      this.mappingDataDetail.listStepDocument = this.dataSendForm.listStepDocument || []
      this.checkError = null
      this.dataMapping.listMedia = []
    },
    clearStepDocuments() {
      this.mappingDataDetail.listStepDocument = this.mappingDataDetail.listStepDocument.map(item => { const { file, ...rest } = item; return rest })
    },
  },
}
</script>
<style scoped>

.v-select span {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.v-select {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.template-name {
  width: 70%;
}

.media-code {
  width: 15%;
}

.quantity {
  width: 15%;
}

label {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.label-width {
  width: 150px;
  text-align: left;
  font-size: 14px !important;
  color: #5e5873 !important;
  min-width: 150px;
  margin-bottom: 1rem;
}

.value-align {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.template-name,
.d-inline-flex span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  max-height: 4.5em;
}

</style>
