<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card no-body>
        <div class="m-2">
          <div class=" search-section mb-2">
            <b-form>
              <b-row>
                <b-col
                  cols="4"
                  md="4"
                >
                  <b-form-group
                    label-for="procedureId"
                  >
                    <v-select
                      id="procedureId"
                      v-model="filter.procedureId"
                      label="label"
                      :options="listProcedure"
                      :reduce="option => option.value"
                      placeholder="Thủ tục"
                      :loading="isLoadingDropdown"
                      class="select-custom"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                  md="4"
                >
                  <b-form-group
                    label-for="procedureType"
                  >
                    <v-select
                      id="procedureType"
                      v-model="filter.procedureType"
                      label="name"
                      :options="listTypeProcedure"
                      :reduce="option => option.id"
                      placeholder="Loại thủ tục"
                      class="select-custom"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                  md="4"
                >
                  <b-form-group label-for="dossierName">
                    <b-form-input
                      id="dossierName"
                      v-model="filter.dossierCode"
                      placeholder="Nhập mã hồ sơ"
                      name="dossierName"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="ml-1"
                    @click="loadItems"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="FilterIcon" /> Lọc
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col cols="12">
              <vue-good-table
                mode="remote"
                :columns="table.fields"
                :rows="table.rows"
                :pagination-options="{ enabled: true }"
                :total-rows="totalRecords"
                class="custom-table"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getDosierStatus(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-b-tooltip.hover
                      v-b-modal.viewProcedureModal
                      title="Xem chi tiết"
                      placement="bottom"
                      variant="info"
                      class="btn-icon"
                      @click="viewDetail(props.row)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </span>
                  <span v-else-if="props.column.field === 'dateSubmit'">
                    {{ formatDate(props.row.dateSubmit) }}
                  </span>
                  <span v-else-if="props.column.field === 'dateReceive'">
                    {{ formatDate(props.row.dateReceive) }}
                  </span>
                  <span v-else-if="props.column.field === 'download'">
                    <b-link :href="props.row.url">
                      <b-button
                        variant="success"
                        class="btn-icon"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                    </b-link>
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRecords }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRecords"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <FormDetailsDossier
        ref="detailsDossierFormRef"
        :data-send-form="currentDossier"
        @succeed="submitDossiersSuccess"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BOverlay, BPagination, BRow, BLink, VBTooltip, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import api from '@/views/one-gate/og-personal-dossiers/personal-dossiers-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { DOSSIER_STATUS, OG_PROCEDURE_TYPE } from '@/const/status'
import vSelect from 'vue-select'
import moment from 'moment'
import FormDetailsDossier from '@/views/one-gate/og-personal-dossiers/FormDetailsDossier.vue'
// eslint-disable-next-line import/no-cycle
// import { debounce } from '@core/utils/utils'
// import { mapDropdown } from '@/utils'

export default {
  name: 'Index',
  components: {
    FormDetailsDossier,
    BFormInput,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    VueGoodTable,
    BFormGroup,
    vSelect,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      isLoadingDropdown: false,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        code: null,
        status: null,
        sort: null,
        procedureId: null,
        procedureType: null,
        dossierCode: '',
      },
      table: {
        fields: [
          {
            label: 'STT',
            field: 'rowNum',
            sortable: false,
            width: '5%',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'Mã hồ sơ',
            field: 'code',
            width: '15%',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Tên thủ tục',
            field: 'procedureName',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Ngày nộp hồ sơ',
            field: 'dateSubmit',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Ngày trả kết quả',
            field: 'dateReceive',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            field: 'status',
            label: 'Trạng thái',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'Mô tả',
            field: 'description',
            sortable: false,
            thClass: 'text-center',
          },
          {
            label: 'Thao tác',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10%',
            sortable: false,
          },
        ],
        rows: [],
      },
      serverParams: {
        columnFilters: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      itemsDisplay: [],
      currentDossier: undefined,
      isPopupSubmitVisible: false,
      searchData: '',
      listProcedure: [],
      listTypeProcedure: [],
      bodySearch: {
        procedureId: null,
        procedureType: null,
        dossierCode: '',
        status: null,
      },
    }
  },
  async created() {
    await Promise.all([
      this.getDataDropdown(),
      this.loadItems(),
      this.getTypeProcedureDataDropdown(),
    ])
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.loadItems()
    },
    async onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      await this.loadItems()
    },
    async onSortChange(params) {
      this.updateParams({
        sort: [{ type: params[0].type, field: params[0].field }],
      })
      await this.loadItems()
    },
    async onColumnFilter(params) {
      this.updateParams(params)
      await this.loadItems()
    },
    async getDataDropdown() {
      this.isLoadingDropdown = true
      await api.getDropdownListProcedure().then(res => {
        this.listProcedure = res
      }).catch(error => {
        this.showToast(error.message || 'Lỗi hệ thống, hãy thử lại sau!', 'XCircleIcon', 'danger')
      }).finally(() => {
        this.isLoadingDropdown = false
      })
    },
    async getTypeProcedureDataDropdown() {
      await api.fetchTypeProcedureData().then(res => {
        this.listTypeProcedure = res
      }).catch(error => {
        this.showToast(error.message || 'Lỗi hệ thống, hãy thử lại sau!', 'XCircleIcon', 'danger')
      }).finally(() => {
        this.isLoadingDropdown = false
      })
    },
    viewDetail(item) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = item
      this.currentDossier = rest
      this.$nextTick(() => {
        this.$bvModal.show('detailsDossierModal')
      })
    },
    loadItems() {
      this.isLoading = true
      const param = {
        currentPage: this.serverParams.page,
        itemsPerPage: this.serverParams.perPage,
      }
      this.bodySearch.procedureId = this.filter.procedureId
      this.bodySearch.procedureType = this.filter.procedureType
      this.bodySearch.dossierCode = this.filter.dossierCode
      this.bodySearch.status = this.filter.status
      api.searchDossier(param, this.bodySearch).then(res => {
        const { records, total } = res.data
        this.itemsDisplay = records
        this.totalRecords = total
        this.table.rows = this.itemsDisplay
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    submitDossiersSuccess() {
      this.$bvModal.hide('detailsDossierModal')
      this.loadItems()
    },
    getTypeProcedure() {
      this.listTypeProcedure = OG_PROCEDURE_TYPE.map(status => ({ value: status.value, text: status.label }))
    },
    getDosierStatus(id) {
      return DOSSIER_STATUS.find(status => status.value === id).label
    },
    formatDate(date) {
      return date ? moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('YYYY/MM/DD HH:mm:ss') : ''
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.select-custom {
  background-color: #FFFFFF;
}

.search-button {
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.custom-table {
  border: 1px solid #dee2e6;
}

.custom-table .vgt-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.custom-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.custom-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table tr:hover {
  background-color: #e9ecef;
}

.search-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

</style>
