<template>
  <validation-observer
    ref="submitProcedureFormRef"
    #default="{ invalid }"
  >
    <b-modal
      id="submitProcedureModal"
      body-class="position-static"
      centered
      title="Nộp hồ sơ"
      modal-class="custom-modal-size"
      no-close-on-backdrop
      no-close-on-esc
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-row>
            <!-- Mã thủ tục -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="code"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width m-auto">Mã thủ tục:</strong>
                  <div class="ml-2 value-align">
                    <label class="m-auto">{{ targetItem.code }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Tên thủ tục -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="name"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Tên thủ tục:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ targetItem.name }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Loại thủ tục -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="procedureType"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Loại thủ tục:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ getProcedureByValue(targetItem.type) }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Quy trình -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Quy trình:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ targetItem.processName }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <!-- Phí -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Phí/Lệ phí:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ formatCurrency(targetItem.fees) }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!--Mở lại sau khi lam phần thanh toán-->
            <div v-show="false">
              <!-- Thông tin ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <div class="d-flex align-items-center">
                  <strong class="label-width">Tên ngân hàng:</strong>
                  <div class="ml-2">
                    <validation-provider name="Thông tin ngân hàng">
                      <b-form-input
                        id="bankName"
                        v-model="bankInfo.bankName"
                        name="bankName"
                        placeholder="Thông tin ngân hàng"
                        aria-describedby="input-1-live-feedback"
                      />
                    </validation-provider>
                  </div>
                </div>
              </b-col>

              <!-- Mã ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Mã ngân hàng:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="bankCode"
                        v-model="bankInfo.bankCode"
                        placeholder="Nhập mã ngân hàng"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <!-- Số tài khoản ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Số tài khoản ngân hàng:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="accountNumber"
                        v-model="bankInfo.accountNumber"
                        placeholder="Nhập số tài khoản"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <!-- Họ tên chủ tài khoản -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Họ tên chủ tài khoản:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="accountHolderName"
                        v-model="bankInfo.accountHolderName"
                        placeholder="Nhập họ tên chủ tài khoản"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </div>
            <!-- Thành phần hồ sơ -->
            <b-col cols="12">
              <b-form-group label-for="documentDetails">
                <div>
                  <strong class="label-width">Hồ sơ đính kèm:</strong>
                  <div class="mt-1">
                    <b-table
                      :key="tableKey"
                      :items="listStepDocument"
                      :fields="documentFields"
                      responsive
                      striped
                      hover
                      :tbody-tr-class="getRowClass"
                    >
                      <template #cell(templateName)="row">
                        <div
                          :title="row.item.templateName"
                          class="template-name"
                        >
                          <span
                            v-show="row.item.isRequired === 1"
                            class="text-danger"
                          >(*)</span>
                          {{ row.item.templateName }}
                        </div>
                      </template>

                      <template #cell(download)="row">
                        <div class="media-list">
                          <div
                            v-for="(media, index) in row.item.mediaIds"
                            :key="index"
                            class="media-item d-flex align-items-center justify-content-between"
                          >
                            <span
                              class="media-name text-truncate"
                              :title="media.mediaName"
                            >
                              {{ media.mediaName }}
                            </span>

                            <b-button
                              v-if="media.mediaCode"
                              v-b-tooltip.hover.top="'Tải về file mẫu'"
                              variant="success"
                              class="btn-icon"
                              style="margin-left: 6px;"
                              size="sm"
                              @click="onDownload(media.mediaCode)"
                            >
                              <feather-icon icon="DownloadIcon" />
                            </b-button>
                          </div>
                        </div>
                      </template>

                      <template #cell(quantity)="row">
                        <div class="quantity text-center d-inline-flex">
                          {{ row.item.mediaIds.length }}
                        </div>
                      </template>

                      <template #cell(upload)="row">
                        <div class="d-inline-flex flex-column">
                          <!-- Hiển thị danh sách file đã upload -->
                          <template v-if="row.item.files && row.item.files.length">
                            <div
                              v-for="(file, index) in row.item.files"
                              :key="index"
                              class="d-flex align-items-center mb-1 justify-content-between"
                            >
                              <span
                                v-b-tooltip.hover.top="file.name"
                                class="text-primary cursor-pointer"
                                @click="onDownload(file)"
                              >
                                {{ file.name }}
                              </span>
                              <b-button
                                v-b-tooltip.hover.top="'Xóa file'"
                                variant="danger"
                                class="btn-icon ml-2"
                                size="sm"
                                @click="onRemoveFile(row.item, index)"
                              >
                                <feather-icon icon="XIcon" />
                              </b-button>
                            </div>
                          </template>
                          <!-- Nút để upload file -->
                          <b-button
                            v-b-tooltip.hover.top="'Đính kèm file thông tin'"
                            variant="info"
                            class="btn-icon mt-2"
                            size="sm"
                            @click="onUpload(row.item)"
                          >
                            <feather-icon icon="PaperclipIcon" />
                          </b-button>
                        </div>
                      </template>
                    </b-table>
                    <div class="col-12 text-right">
                      <span class="text-danger">(*)</span>
                      <span class="note"> Tệp cần tải lên</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>

      <!-- Modal Footer -->
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            variant="primary"
            class="mr-2"
            :disabled="isEnableStatus"
            @click="onSubmit(DossierStatus.WAITING_FOR_RECEPTION)"
          >
            <span class="text-right">
              <feather-icon icon="SaveIcon" /> Nộp
            </span>
          </b-button>
          <b-button
            variant="success"
            class="mr-2"
            :disabled="enableDraft"
            @click="onSubmit(DossierStatus.DRAFT)"
          >
            <span class="text-right">
              <feather-icon icon="CheckSquareIcon" /> Lưu Nháp
            </span>
          </b-button>

          <b-button
            variant="outline-secondary"
            @click="$bvModal.hide('submitProcedureModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Đóng
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BButton, BRow, BCol, BFormInput, BFormGroup, BTable, BOverlay, BModal, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import api from '@/views/one-gate/og-submit-dossiers/submit-dossiers-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  DOSSIER_STATUS, FILE_UPLOAD_DOSSIER_STATUS, OG_PROCEDURE_TYPE, TEMPLATE_FILE_TYPE,
} from '@/const/status'
import Ripple from 'vue-ripple-directive'
import { DossierStatus } from '@/const/dossier-status'

export default {
  name: 'FormSubmit',
  components: {
    BModal,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    modalShow: { type: Boolean, default: false },
    item: { type: Object, default: undefined },
    dataSendForm: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        id: '', name: '', code: '', type: '', processId: '', processName: '', dossierId: '', fees: 0, status: 0,
      },
      bankInfo: {
        bankName: '', bankCode: '', accountNumber: '', accountHolderName: '',
      },
      documentFields: [
        { key: 'templateName', label: 'Tên giấy tờ', thStyle: { width: '350px' } },
        {
          key: 'download', label: 'Tải về file mẫu', thStyle: { width: '40%', textAlign: 'center' }, tdClass: 'text-center',
        },
        {
          key: 'quantity', label: 'Số lượng', thStyle: { width: '10%', textAlign: 'center' }, tdClass: 'text-center',
        },
        {
          key: 'upload', label: 'Đính kèm file thông tin', thStyle: { width: '30%', textAlign: 'center' }, tdClass: 'text-center',
        },
      ],
      listProcessByProcedureId: [],
      listInfoDepartment: [],
      listStepDocument: [],
      isEnable: true,
      tableKey: Date.now(),
      fileNumber: 0,
      dataMapping: {
        dossierId: null,
        procedureId: null,
        processId: null,
        bankName: '',
        bankCode: '',
        accountNumber: '',
        accountHolderName: '',
        status: null,
        listMedia: [],
      },
      sessionMedia: [],
      checkError: null,
      stepRequireFile: 0,
    }
  },
  computed: {
    DossierStatus() {
      return DossierStatus
    },
    isEnableStatus() {
      return this.fileNumber !== this.stepRequireFile || this.checkError
    },
    enableDraft() {
      return this.isEnableStatus || this.targetItem.dossierId || this.checkError
    },
  },
  mounted() {
    const style = document.createElement('style')
    style.innerHTML = '.custom-dialog { max-width: 50% !important; width: 50% !important; margin: auto !important; }'
    document.head.appendChild(style)
  },
  methods: {
    onShow() {
      this.resetData()
      this.listStepDocument.forEach(data => {
        if (data.isRequired === 1) {
          this.stepRequireFile += data.mediaIds.length
        }
      })
    },
    onHide() {
      this.clearStepDocuments()
      this.tableKey = Date.now()
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        const type = this.getIdTemplate(1)
        this.url = await api.downloadTemplate({ objectPath, type })
        if (this.url) window.open(this.url, '_blank')
      } catch (e) {
        this.showToast(e.message, 'XCircleIcon', 'danger')
      } finally { this.isLoading = false }
    },
    onUpload(row) {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = '.pdf,.docx'
      fileInput.multiple = true // Cho phép upload nhiều file
      fileInput.click()
      fileInput.addEventListener('change', this.handleFileChange.bind(this, row))
    },
    handleFileChange(row, event) {
      const files = Array.from(event.target.files) // Lấy danh sách file
      const maxFileSize = 50 * 1024 * 1024 // 50MB

      files.forEach(file => {
        if (file.size > maxFileSize) {
          this.showToast(`File "${file.name}" vượt quá dung lượng tối đa 50MB`, 'ErrorIcon', 'error')
          return // Bỏ qua file không hợp lệ
        }

        // Nếu file hợp lệ, thêm vào danh sách file
        const fileData = {
          file,
          name: file.name,
          size: file.size,
          stepId: row.stepId,
          templateId: row.templateId,
          status: this.getFileDossierStatus(1),
        }

        if (!row.files) {
          // Nếu chưa có danh sách file, khởi tạo mảng
          this.$set(row, 'files', [])
        }
        row.files.push(fileData) // Thêm file vào danh sách
        this.tableKey += 1

        if (row.isRequired === 1) {
          this.fileNumber += 1
        }
        this.showToast(`File "${file.name}" đã được tải lên thành công`, 'CheckIcon', 'success')
      })
    },
    async onSubmit(type) {
      this.isLoading = true
      try {
        await this.uploadDossierFile(type)
      } catch (error) {
        this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    async finalSubmitDossier(type) {
      this.dataMapping.procedureId = this.targetItem.id
      this.dataMapping.processId = this.targetItem.processId
      this.dataMapping.dossierId = this.targetItem.dossierId
      this.dataMapping.bankName = this.bankInfo.bankName
      this.dataMapping.bankCode = this.bankInfo.bankCode
      this.dataMapping.accountHolderName = this.bankInfo.accountHolderName
      this.dataMapping.accountNumber = this.bankInfo.accountNumber
      this.dataMapping.status = type
      await api.submitDossiers(this.dataMapping).then(response => {
        this.targetItem = { ...this.targetItem, dossierId: response.data.id }
        this.showToast(response.message, 'CheckIcon', 'success')
        if (this.dataMapping.status !== 5) {
          this.$emit('submitDossiersSuccess', true)
        }
      }).catch(error => {
        if (this.sessionMedia.length > 0) {
          api.deleteListMediaId(this.sessionMedia)
        }
        this.checkError = Date.now()
        this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
      }).finally(() => {
        this.sessionMedia = []
      })
    },
    async uploadDossierFile(type) {
      const formData = new FormData()

      // Lọc các file hợp lệ từ danh sách `listStepDocument`
      const validFiles = this.listStepDocument.filter(
        item => item.files
          && item.files.length
          && item.files.some(file => file.status === this.getFileDossierStatus(1)), // Chỉ lấy file có status hợp lệ
      )

      // Duyệt qua các file hợp lệ và thêm vào formData
      validFiles.forEach(item => {
        item.files.forEach((file, index) => {
          if (file.status === this.getFileDossierStatus(1)) {
            // Thêm số thứ tự (index) vào key nếu có nhiều file trong cùng stepId và templateId
            formData.append(
              `files[${item.stepId}_${item.templateId}_${index + 1}]`, // Thêm stt (index + 1)
              file.file,
            )
          }
        })
      })

      // Kiểm tra nếu có file trong formData
      if ([...formData.entries()].length) {
        // Gửi yêu cầu upload tệp
        this.sessionMedia = await api.uploadDossierFile({ formData })
        this.dataMapping.listMedia.push(...this.sessionMedia)

        // Cập nhật trạng thái của các file sau khi upload thành công
        this.listStepDocument.forEach(item => {
          if (item.files && item.files.length) {
            item.files.forEach(file => {
              if (file.status === this.getFileDossierStatus(1)) {
                // eslint-disable-next-line no-param-reassign
                file.status = this.getFileDossierStatus(2) // Cập nhật trạng thái sau khi upload
              }
            })
          }
        })
      }

      // Gửi yêu cầu final submit
      await this.finalSubmitDossier(type)
    },
    onRemoveFile(row, index) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa file này không?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
        cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const removedFile = row.files.splice(index, 1) // Xóa file khỏi danh sách
          const indexStep = this.listStepDocument.findIndex(doc => doc.stepId === row.stepId && doc.templateId === row.templateId)
          this.showToast(`File "${removedFile[0].name}" đã được xóa`, 'XIcon', 'info')
          if (indexStep !== -1) {
            // Xóa file trong danh sách listStepDocument
            this.$set(this.listStepDocument[indexStep], 'file', null)

            // Lọc và cập nhật lại danh sách this.dataMapping.listMedia
            this.dataMapping.listMedia = this.dataMapping.listMedia.filter(
              data => data.title !== `${row.stepId}_${row.templateId}`,
            )
            this.tableKey += 1
            if (row.isRequired === 1 && removedFile.length) {
              this.fileNumber -= 1
            }
            this.isEnable = true
          }
        }
      })
    },
    getRowClass(item) {
      // Kiểm tra nếu số lượng file đã upload khác với số lượng file mẫu
      if (item.isRequired === 0) {
        return ''
      }
      if (item.isRequired === 1 && item.files && item.files.length !== item.mediaIds.length) {
        return 'row-border-red' // Màu đỏ nếu không khớp
      } if (item.isRequired === 1 && item.files && item.files.length === item.mediaIds.length) {
        return 'row-border-green' // Màu xanh nếu khớp
      }
      return 'row-border-red'
    },

    formatCurrency(value) { return value ? `${value.toLocaleString('vi-VN')} VND` : '0 VND' },
    getIdTemplate(value) { return TEMPLATE_FILE_TYPE.find(item => item.value === value).value },
    getFileDossierStatus(value) { return FILE_UPLOAD_DOSSIER_STATUS.find(item => item.value === value).value },
    getProcedureByValue(value) { return value ? OG_PROCEDURE_TYPE.find(item => item.value === value).label : value },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: { title, icon, variant },
      })
    },
    resetData() {
      this.listInfoDepartment = this.listStepDocument = this.sessionMedia = []
      this.tableKey = Date.now()
      this.fileNumber = 0
      this.stepRequireFile = 0
      this.isEnable = true
      this.bankInfo = {}
      this.targetItem = this.dataSendForm.targetItem || {}
      this.listStepDocument = this.dataSendForm.listStepDocument || []
      this.checkError = null
      this.dataMapping.listMedia = []
      // this.targetItem.dossierId = 9
    },
    clearStepDocuments() {
      this.listStepDocument = this.listStepDocument.map(item => { const { file, ...rest } = item; return rest })
    },
  },
}
</script>
<style>

.v-select span {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.v-select {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.media-code {
  width: 15%;
}

.quantity {
  width: 15%;
}

label {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.label-width {
  width: 150px;
  text-align: left;
  font-size: 14px !important;
  color: #5e5873 !important;
  min-width: 150px;
  margin-bottom: 1rem;
}

.value-align {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.template-name,
.d-inline-flex span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  max-height: 4.5em;
}

.note {
  font-style: italic;
  font-size: 0.85rem;
  color: #6c757d; /* Màu ghi chú */
}

.media-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.media-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media-name {
  font-size: 14px;
  color: #333;
  margin-right: 8px;
}

.media-name {
  display: inline-block;
  max-width: 350px; /* Độ rộng tối đa hiển thị */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #333;
  margin-right: 8px;
  cursor: pointer;
}

tr.row-border-red {
  background-color: rgba(255, 0, 0, 0.1) !important; /* Màu nền đỏ nhạt */
}

.row-border-green {
  background-color: rgba(0, 255, 0, 0.1) !important; /* Màu nền xanh nhạt */
}
::v-deep(.row-border-red) {
  background-color: rgba(255, 0, 0, 0.1) !important; /* Màu nền đỏ nhạt */
}

::v-deep(.row-border-green) {
  background-color: rgba(0, 255, 0, 0.1) !important; /* Màu nền xanh nhạt */
}
</style>
