import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async fetchItems(params) {
    try {
      const response = await axios.get('oneGate/procedure/searchProcedures', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data
      }
    } catch (e) {
      handleCatch(e)
      this.isLoading = false
    }
  },
  // eslint-disable-next-line consistent-return
  async getDropdownListProcess(params) {
    try {
      this.isLoading = true
      const response = await axios.get(`oneGate/process/findByProcedureId/${params.procedureId}`)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
      this.isLoading = false
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async selectInfoProcedure(params) {
    try {
      const response = await axios.get(`oneGate/dossiers/selectInfoProcedure/${params.processId}`)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async downloadTemplate(params) {
    try {
      const response = await axios.get('oneGate/template-files/download', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async uploadDossierFile({ formData }) {
    try {
      const response = await axios.post(
        'oneGate/dossiers/uploadDossierFile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
      return []
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async submitDossiers(dossierData) {
    try {
      const response = await axios.post('oneGate/dossiers/submitDossier', dossierData)

      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data
      }
      return []
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  // eslint-disable-next-line consistent-return
  async deleteListMediaId(listObject) {
    try {
      const mediaIds = listObject.map(item => item.mediaId)
      const response = await axios.post('uni/media/deleteListMediaId', mediaIds)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
  },
}
